<template>
  <div>
    <MarkupForm
      :active="active"
      :form-data="formData"
      :close-handler="closeHandler"
      :mode="mode"
      :refresh-fn="refreshFn"
    />
    <BaseTableWrapper :table-init="tableInit" style="padding-top: 0">
      <h1 class="page-header">Markups</h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import MarkupForm from '@/components/MarkupForm.vue'
import MarkupActions from '@/components/MarkupActions.vue'
import markups from '@/services/markups'
import Vue from 'vue'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'
import { customerPredefined } from '@/utils/predefined'

const daysMap = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}

export default {
  components: {
    BaseTableWrapper,
    MarkupForm,
  },
  data() {
    return {
      isAdmin: false,
      active: false,
      mode: 'view',
      refreshFn: null,
      formData: {
        markupId: null,
        markupTypeId: null,
        markupPercentage: null,
        name: '',
        companyId: null,
        customerId: null,
        customerAccountId: null,
        industryId: null,
        eventId: null,
        tripStartDate: null,
        tripEndDate: null,
        pricingStartDate: null,
        pricingEndDate: null,
        markupType: {
          markupTypeId: null,
          label: '',
          key: '',
          description: '',
        },
        markupDays: [
          // {
          //   markupDayId: 45,
          //   markupId: 44,
          //   day: 3
          // },
          // {
          //   markupDayId: 46,
          //   markupId: 44,
          //   day: 2
          // }
        ],
      },
      tableInit: {
        id: 'markup-list',
        detailKeyId: 'markupId',
        tableId: 'markup_tv_view',
        addNewEnabled: true,
        areStatusFiltersShowing: true,
        addNewHandler: this.addNew,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        forceRefresh: 0,
        columns: [],
        collection: 'Markups',
        action: (params) => markups.tableView(params),
        enableColumnConfig: true,
      },
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    let columns = [
      {
        _t_id: '66cezza342',
        prop: '/',
        text: 'Actions',
        component: this.createComponent(MarkupActions, this.viewAndEdit),
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: '694aa3ec',
        prop: 'markupId',
        text: 'ID',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        detail: false,
        defaultSort: true,
      },
      {
        _t_id: '694aa806',
        prop: 'name',
        text: 'Name',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'name',
        detail: false,
      },
      {
        _t_id: '694aa626',
        prop: ['markupType/label'],
        text: 'Markup',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'markupType.label',
        detail: false,
      },
      {
        _t_id: '694aa720',
        prop: 'markupPercentage',
        text: 'Markup %',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'markupPercentage',
        detail: false,
      },
      {
        _t_id: '694aa8d8',
        prop: 'markupDays',
        text: 'Days',
        // sort: true,
        // filter: true,
        type: 'text',
        filterType: 'contains',
        computedText: (days) => {
          const sortedDays = [...days]
          return sortedDays
            .sort((a, b) => a.day - b.day)
            .map((d) => daysMap[d.day])
            .join(', ')
        },
        // sortProp: 'address/street1',
        detail: false,
      },
      {
        _t_id: '3db7727d',
        prop: 'vehicleType/label',
        text: 'Vehicle Type',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'vehicleType',
        detail: false,
      },
      {
        _t_id: 'bb899b2b',
        prop: 'rateType/label',
        text: 'Rate Type',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'rateType',
        detail: false,
      },
      {
        _t_id: '2212942e',
        prop: 'biddingMarket/marketName',
        text: 'Bidding Market',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'biddingMarket',
        detail: false,
      },
      {
        _t_id: 'abf89ea4-8d9d-431c-a7d7-a550c682f90e',
        prop: 'tripMarket/marketName',
        text: 'Trip Market',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'tripMarket',
        detail: false,
      },
      {
        _t_id: '694aa9a0',
        prop: ['customer/firstName', 'customer/lastName'],
        text: 'Customer',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'customer/lastName',
        detail: false,
        filterProp: ['customer/firstName', 'customer/lastName'],
        predefined: deepClone(customerPredefined),
        defaultHidden: true,
      },
      {
        _t_id: '694aac8e',
        prop: ['customerAccount/name'],
        text: 'Customer Account',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'customerAccount.name',
        filterProp: 'customerAccount.name',
        detail: false,
        defaultHidden: true,
      },
      {
        _t_id: '694aad7e',
        prop: ['industry/label'],
        text: 'Industry',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'industry.label',
        detail: false,
        defaultHidden: true,
      },
      {
        _t_id: '694aae3c',
        prop: ['event/title'],
        text: 'Event',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'event.title',
        detail: false,
      },
      {
        _t_id: '6c170270-g91p-4678-8ac3-e5f6c0076d2e',
        prop: ['pricingCompany/name'],
        text: 'Company',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'pricingCompany/name',
        detail: false,
      },
      {
        _t_id: '694aaefa',
        prop: 'tripStartDatetime',
        text: 'Trip Start Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'tripStartDatetime',
        detail: false,
      },
      {
        _t_id: '694aafc2',
        prop: 'tripEndDatetime',
        text: 'Trip End Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'tripEndDatetime',
        detail: false,
      },
      {
        _t_id: '6c170270-b01e-4678-8ac3-e5f6c0076d2e',
        prop: 'pricingStartDatetime',
        text: 'Pricing Start Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'pricingStartDatetime',
        detail: false,
      },
      {
        _t_id: '641b0add-6eed-468d-a5f4-78ac690d0013',
        prop: 'pricingEndDatetime',
        text: 'Pricing End Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'pricingEndDatetime',
        detail: false,
      },
      {
        _t_id: '97611019-3dcf-47b6-a8bc-e2bd8fb2cddb',
        prop: 'splitId',
        text: 'Split ID',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'splitId',
        detail: false,
      },
      {
        _t_id: 'markup_marketplace_filter',
        filterType: 'eq',
        filterProp: 'marketplace',
      },
    ]

    this.tableInit.columns.push(...columns)
  },
  methods: {
    addNew() {
      this.active = true
      this.mode = 'add'
    },
    viewAndEdit(data) {
      this.active = true
      this.formData = data
      this.mode = 'view'
    },
    closeHandler(refreshQuery) {
      if (refreshQuery) {
        this.tableInit.forceRefresh = 1 + Math.random()
      }
      this.active = false
    },
    createComponent(component, handler) {
      return Vue.component('WrappedActions', {
        props: {
          row: {
            type: Object,
            default: () => ({}),
          },
          handleAction: {
            type: Function,
            default: () => null,
          },
          rowProps: {
            type: Object,
            default: () => ({}),
          },
        },
        render(createElement) {
          return createElement(component, {
            props: {
              row: this.row,
              handleAction: this.handleAction,
              rowProps: this.rowProps,
              handler: () => {
                handler(this.row)
              },
            },
          })
        },
      })
    },
  },
}
</script>

<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
