/* eslint-disable no-empty */ /* eslint-disable no-empty */
<template>
  <CRSidebarDialog
    v-model="active"
    persistent
    @input="closeHandlerWrapper(false)"
  >
    <template #title>
      {{ internalMode === 'add' ? 'Add' : 'Edit' }} Markup
    </template>
    <div class="markup-sidebar" :title="null">
      <v-form ref="markup">
        <div class="markup-sidebar--markup-form">
          <div>
            <CRInput
              id="markup-form-name"
              v-model="model.name"
              label="Markup"
              placeholder="Markup Name"
              :disabled="internalMode === 'view'"
              type="text"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Markup Name is Required',
                  error: 'Markup Name is Required',
                }),
              ]"
            />
            <v-divider/>
            <CRSelect
              id="markup-market-type"
              v-model="marketType"
              return-object
              :disabled="internalMode === 'edit' || internalMode === 'view'"
              label="Market Type"
              tooltip="E.g., Do we apply the markup based on the trip's origin
              or where the operator is coming from?"
              placeholder="Select market type"
              item-value="marketType"
              item-text="label"
              :items="marketTypes"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Market Type is Required',
                  error: 'Market Type is Required',
                }),
            ] "
            />
            <div v-if="marketType == 'Bidding'">
              <v-flex class="subtitle">Operator Sourcing (Bidding) Market</v-flex>
              <CRSelect
                :id="`select-market`"
                v-model="model.biddingMarkets"
                flat
                placeholder="Select Bidding Market"
                :disabled="internalMode === 'edit' || internalMode === 'view'"
                multiple
                :items="markets"
                :item-text="'marketName'"
                :item-value="'biddingMarketId'"
                :return-object="true"
                :menu-props="{ overflowY: true, maxHeight: '200px !important' }"
                :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Bidding Market is Required',
                  error: 'Bidding Market is Required',
                }),
            ] "
              >
                <template #prepend-item>
                  <v-list-tile
                    style="cursor: pointer"
                    @click="toggleAllBiddingMarketsSelected"
                  >
                    <v-checkbox
                      v-model="marketsAll"
                      hide-details
                      class="ma-0 pa-0"
                      style="width: 100%:"
                      @change="toggleAllBiddingMarketsSelected"
                    ></v-checkbox>
                    <span style="flex: 14">All</span>
                  </v-list-tile>
                </template>
              </CRSelect>
            </div>
            <div v-if="marketType == 'Trip'">
              <v-flex class="subtitle">Trip Market</v-flex>
              <CRSelect
                :id="`select-trip-market`"
                v-model="model.tripMarkets"
                flat
                placeholder="Select Trip Market"
                :disabled="internalMode === 'edit' || internalMode === 'view'"
                multiple
                :items="markets"
                :item-text="'marketName'"
                :item-value="'tripMarketId'"
                :return-object="true"
                :menu-props="{ overflowY: true, maxHeight: '200px !important' }"
                :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Trip Market is Required',
                  error: 'Trip Market is Required',
                }),
            ] "
              >
                <template #prepend-item>
                  <v-list-tile
                    style="cursor: pointer"
                    @click="toggleAllTripMarketsSelected"
                  >
                    <v-checkbox
                      v-model="marketsAll"
                      hide-details
                      class="ma-0 pa-0"
                      style="width: 100%:"
                      @change="toggleAllTripMarketsSelected"
                    ></v-checkbox>
                    <span style="flex: 14">All</span>
                  </v-list-tile>
                </template>
              </CRSelect>
            </div>
            <v-divider/>
            <CRSelect
              id="markup-date-type"
              v-model="dateType"
              return-object
              :disabled="internalMode === 'view'"
              label="Date Type"
              tooltip="E.g., Do we apply the markup based on the time of pricing or when a trip
              occurs?"
              placeholder="Select date type"
              item-value="dateType"
              item-text="label"
              :items="dateTypes"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Date Type is Required',
                  error: 'Date Type is Required',
                }),
            ] "
            />
            <v-flex v-show="dateType == 'Trip'">
              <v-layout padded align-center>
                <v-flex>
                  <CRInput
                    id="markup-form-start-date"
                    v-model="model.tripStartDatetime"
                    :disabled="internalMode === 'view'"
                    label="Trip Start Date"
                    type="date"
                    :rules="tripStartDateRules"
                  />
                </v-flex>
                <v-flex>
                  <CRInput
                    id="markup-form-expiration-date"
                    v-model="model.tripEndDatetime"
                    :disabled="internalMode === 'view'"
                    label="Trip End Date"
                    type="date"
                    :rules="tripEndDateRules"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-show="dateType == 'Pricing'">
              <v-layout padded align-center>
                <v-flex>
                  <CRInput
                    id="markup-form-pricing-start-date"
                    v-model="model.pricingStartDatetime"
                    :disabled="internalMode === 'view'"
                    label="Pricing Start Date"
                    type="date"
                    :rules="pricingStartDateRules"
                  />
                </v-flex>
                <v-flex>
                  <CRInput
                    id="markup-form-pricing-expiration-date"
                    v-model="model.pricingEndDatetime"
                    :disabled="internalMode === 'view'"
                    label="Pricing End Date"
                    type="date"
                    :rules="pricingEndDateRules"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <CRSelect
              v-if="dateType == 'Trip'"
              id="markup-form-day"
              v-model="model.markupDays"
              :disabled="internalMode === 'view'"
              multiple
              label="Trip Days"
              placeholder="Select day"
              item-text="description"
              item-value="day"
              return-object
              :items="markupDays"
            />
          </div>
          <v-divider />
          <CRSelect
            id="markup-form-type"
            v-model="model.markupType"
            return-object
            :disabled="internalMode === 'edit' || internalMode === 'view'"
            label="Markup Type"
            placeholder="Select type"
            item-value="markupTypeId"
            item-text="label"
            :items="markupTypes"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Markup Type is Required',
                error: 'Markup Type is Required',
              }),
            ]"
          />
          <v-layout v-if="model.markupType" column>
            <CRInput
              v-if="shouldShowFnWrapper('Customer')"
              id="markup-form-customer"
              key="markup-form-customer-select"
              v-model="model.customer"
              :disabled="internalMode === 'edit' || internalMode === 'view'"
              label="Customer"
              placeholder="Type to search for a customer"
              type="autocomplete"
              :clearable="true"
              class="margin-bottom-10"
              item-value="customerId"
              item-text="fullName"
              return-object
              :items="customers"
              :search-input.sync="customerSync"
            />
            <CRInput
              v-if="shouldShowFnWrapper('Customer Account')"
              id="markup-form-customer-account"
              key="markup-form-customer-account-select"
              v-model="model.customerAccount"
              :disabled="internalMode === 'edit' || internalMode === 'view'"
              label="Customer Account"
              placeholder="Type to search for a Customer Account"
              type="autocomplete"
              :clearable="true"
              class="margin-bottom-10"
              return-object
              item-value="customerAccountId"
              item-text="name"
              :items="customerAccounts"
              :search-input.sync="customerAccountSync"
            />
            <v-layout
              v-if="shouldShowFnWrapper('Industry')"
              column
              class="margin-bottom-10"
            >
              <IndustrySelector
                id="markup-form-industry"
                :key="`industry-selector-${formData.markupId}`"
                ref="industrySelector"
                v-model="model.industryId"
                :initial-industry="model.industryId"
                :mode="internalMode"
              />
            </v-layout>
            <div v-if="shouldShowFnWrapper('Vehicle Type')">
              <CRSelect
                id="markup-form-vehicle-type"
                v-model="model.vehicleTypes"
                :disabled="internalMode === 'edit' || internalMode === 'view'"
                multiple
                label="Vehicle Type"
                placeholder="Select vehicle type"
                item-value="key"
                return-object
                item-text="label"
                :items="vehicleTypes"
              />
              <CRSelect
                id="markup-form-rate-type"
                v-model="model.rateTypes"
                :disabled="internalMode === 'edit' || internalMode === 'view'"
                multiple
                label="Rate Type"
                placeholder="Select rate type"
                item-value="key"
                return-object
                item-text="label"
                :items="rateTypes"
              />
            </div>
            <CRInput
              v-if="shouldShowFnWrapper('Event')"
              id="markup-form-event"
              v-model="model.event"
              :disabled="internalMode === 'edit' || internalMode === 'view'"
              class="margin-bottom-10"
              label="Event"
              type="autocomplete"
              :clearable="true"
              return-object
              item-value="eventId"
              item-text="title"
              :items="events"
              :search-input.sync="eventsSync"
            />
            <div v-if="shouldShowFnWrapper('Area of Interest')">
              <CRSelect
                id="markup-form-area-of-interest"
                v-model="model.areaOfInterestType"
                :disabled="internalMode === 'view'"
                label="Area of Interest Type"
                placeholder="Select Type"
                item-value="key"
                return-object
                item-text="label"
                :items="areaTypeOptions"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Area of Interest Type is Required',
                    error: 'Area of Interest Type is Required',
                  }),
                ]"
              ></CRSelect>
            </div>
            <CRInput
              v-if="shouldShowFnWrapper('Market')"
              id="markup-form-market-company"
              key="markup-form-market-company-select"
              v-model="model.company"
              :disabled="internalMode === 'edit' || internalMode === 'view'"
              label="Company"
              placeholder="Type to search for a company"
              type="autocomplete"
              :clearable="true"
              class="margin-bottom-10"
              item-value="companyId"
              item-text="name"
              return-object
              :items="companies"
              :search-input.sync="companySync"
            />
          </v-layout>
          <CRInput
            id="markup-form-percentage"
            v-model="model.markupPercentage"
            :disabled="internalMode === 'view'"
            label="Value"
            placeholder="Markup %"
            type="text"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Markup % is Required',
                error: 'Markup % is Required',
              }),
              (val) => isValidNumber(val) || 'Invalid Markup %'
            ]"
          />
          <v-divider />
          <CRSelect
            id="markup-form-urgency-high"
            v-model="model.urgencyHoursHigh"
            :disabled="internalMode === 'view'"
            label="Time to Pickup High"
            placeholder="Select hours"
            :items="highUrgencyHours"
            item-value="hours"
            item-text="label"
          />

          <CRSelect
            id="markup-form-urgency-low"
            v-model="model.urgencyHoursLow"
            :disabled="internalMode === 'view'"
            label="Time to Pickup Low"
            placeholder="Select hours"
            :items="lowUrgencyHours"
            item-value="hours"
            item-text="label"
          />
          <v-divider />

          <template>
            <div>
              <v-switch
                v-model="applyBasedOnOperatorCost"
                label="Apply based on operator cost"
              />

              <div v-if="applyBasedOnOperatorCost">
                <CRInput
                  id="operator-cost-high-number-input"
                  v-model="model.operatorCostHigh"
                  :disabled="internalMode === 'view'"
                  :rules="[
                    validateOperatorCost
                  ]"
                  label="Operator Cost High"
                  placeholder="High Cost"
                  type="number"
                />

                <CRInput
                  id="operator-cost-low-number-input"
                  v-model="model.operatorCostLow"
                  :disabled="internalMode === 'view'"
                  :rules="[
                    validateOperatorCost
                  ]"
                  label="Operator Cost Low"
                  placeholder="Low Cost"
                  type="number"
                />
              </div>
            </div>
          </template>
          <v-divider />
          <CRInput
            id="markup-form-split-id"
            v-model="model.splitId"
            :disabled="internalMode === 'view'"
            label="Split ID"
            placeholder="Feature Flag Name"
            type="text"
          />
          <CRInput
            id="markup-form-external-id"
            v-model="model.externalId"
            :disabled="internalMode === 'view'"
            label="External ID"
            placeholder="Markup ID in Split"
            type="text"
          />
        </div>
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <div v-if="internalMode === 'add'">
          <CRButton
            v-if="internalMode !== 'view'"
            id="markup-sidebar-save-btn"
            class="markup-sidebar--action-btn"
            color="primary"
            @click="addHandler"
          >
            Save
          </CRButton>
        </div>
        <div v-else-if="internalMode === 'view'">
          <CRButton
            id="markup-sidebar-edit-btn"
            class="markup-sidebar--action-btn"
            color="primary"
            @click="editHandler"
          >
            Edit
          </CRButton>
        </div>
        <div v-else-if="internalMode === 'edit'">
          <div class="cr-sidebar-dialog--button-spacer"></div>
          <CRButton
            v-if="internalMode === 'edit'"
            id="markup-sidebar-delete-btn"
            class="markup-sidebar--delete-btn"
            text-color="red"
            @click="deleteHandler"
          >
            Delete
          </CRButton>
          <CRButton
            v-if="internalMode !== 'view'"
            id="markup-sidebar-save-btn"
            class="markup-sidebar--action-btn"
            color="primary"
            @click="saveHandler"
          >
            Save
          </CRButton>
        </div>
      </v-form>
    </div>
  </CRSidebarDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customers from '@/services/customers'
import affiliates from '@/services/affiliates'
import customerAccounts from '@/services/customerAccounts'
import events from '@/services/events'
import industries from '@/services/industries'
import markets from '@/services/markets'
import markups from '@/services/markups'
import { isRequired, isNotEmpty, isValidNumber } from '@/utils/validators'
import { filter } from '@/utils/filter'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'
import IndustrySelector from '@/components/IndustrySelector.vue'
import { EventBus } from '@/utils/event-bus'
import { getOffsetDate, daysMap } from '@/utils/time'

export default {
  components: {
    IndustrySelector,
  },
  props: {
    mode: {
      type: String,
      default: 'view',
    },
    active: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    closeHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isValidNumber,
      internalFormData: {},
      marketType: null,
      dateType: null,
      applyBasedOnOperatorCost: false,
      model: {
        markupType: null,
        markupDays: [],
        tripStartDatetime: null,
        tripEndDatetime: null,
        pricingStartDatetime: null,
        pricingEndDatetime: null,
        name: null,
        markupPercentage: null,
        industryId: null,
        event: null,
        customer: null,
        company: null,
        customerAccount: null,
        biddingMarkets: [],
        tripMarkets: [],
        vehicleTypes: [],
        rateTypes: [],
        urgencyHoursLow: null,
        urgencyHoursHigh: null,
        operatorCostLow: null,
        operatorCostHigh: null,
        splitId: null,
        externalId: null,
      },
      markets: [],
      marketsAll: false,
      markupTypes: [],
      marketTypes: ['Bidding', 'Trip'],
      dateTypes: ['Pricing', 'Trip'],
      vehicleTypes: [],
      rateTypes: [
        {
          label: 'Hourly',
          key: 'hourly_rate',
        },
        {
          label: 'Daily',
          key: 'daily_rate',
        },
        {
          label: 'Mileage',
          key: 'mileage',
        },
      ],
      markupDays: daysMap,
      customerSync: null,
      eventsSync: null,
      companySync: null,
      formSubmitted: false,
      customerAccountSync: null,
      industries: [],
      customers: [],
      companies: [],
      customerAccounts: [],
      events: [],
      internalMode: 'add',
      internalTypeMode: null,
      isSearching: null,
      urgencyHours: [
        {
          label: '30 days',
          hours: 720
        },
        {
          label: '7 days',
          hours: 168
        },
        {
          label: '48 hours',
          hours: 48,
        },
        {
          label: '24 hours',
          hours: 24,
        },
        {
          label: '12 hours',
          hours: 12,
        },
        {
          label: '6 hours',
          hours: 6,
        },
        {
          label: '0 hours',
          hours: 0,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    marketType() {
      return this.marketType
    },
    dateType() {
      return this.dateType
    },
    hasOptions() {
      if (!this.model.markupType) return true

      if (
        this.internalMode === 'edit' ||
        (this.model.markupType &&
          (this.model.markupType.label === 'Days' ||
            this.model.markupType.label === 'Date Range'))
      ) {
        return false
      }

      return true
    },
    tripStartDateRules() {
      if (
        this.dateType !== 'Trip'
      ) {
        return []
      }
      return [
        isRequired(true, isNotEmpty, {
          req: 'Trip Start Date is Required',
          error: 'Trip Start Date is Required',
        }),
      ]
    },
    tripEndDateRules() {
      if (
        this.dateType !== 'Trip'
      ) {
        return [
          (val) =>
            val < this.model.tripStartDatetime
              ? 'Trip Start Date must not be after Trip End Date'
              : true,
        ]
      }
      return [
        isRequired(true, isNotEmpty, {
          req: 'Trip End Date is Required',
          error: 'Trip End Date is Required',
        }),
        (val) =>
          val < this.model.tripStartDatetime
            ? 'Start Date must not be after End Date'
            : true,
      ]
    },
    pricingStartDateRules() {
      if (
        this.dateType !== 'Pricing'
      ) {
        return []
      }
      return [
        isRequired(true, isNotEmpty, {
          req: 'Pricing Start Date is Required',
          error: 'Pricing Start Date is Required',
        }),
      ]
    },
    pricingEndDateRules() {
      if (
        this.dateType !== 'Pricing'
      ) {
        return [
          (val) =>
            val < this.model.pricingStartDatetime
              ? 'Pricing Date must not be after Trip End Date'
              : true,
        ]
      }
      return [
        isRequired(true, isNotEmpty, {
          req: 'Pricing End Date is Required',
          error: 'Pricing End Date is Required',
        }),
        (val) =>
          val < this.model.pricingStartDatetime
            ? 'Pricing Start Date must not be after Pricing End Date'
            : true,
      ]
    },
    highUrgencyHours() {
      return [
        {
          label: 'No Maximum',
          hours: null
        },
        ...this.urgencyHours.filter(
          (ua) =>
            (ua.hours > 0 && this.model.urgencyHoursLow === null) ||
            ua.hours > this.model.urgencyHoursLow
        ),
      ]
    },
    lowUrgencyHours() {
      return [
        ...this.urgencyHours.filter(
          (ua) =>
            this.model.urgencyHoursHigh === null ||
            ua.hours < this.model.urgencyHoursHigh
        ),
        {
          label: 'No Minimum',
          hours: null
        },
      ]
    },
    ...mapGetters({ areaTypeOptions: 'types/geofenceTypes' }),
  },
  watch: {
    async customerSync(value) {
      if (this.isSearching) {
        clearTimeout(this.isSearching)
        this.isSearching = setTimeout(async () => {
          await this.searchCustomers(value)
          this.isSearching = null
        }, 500)
      }
      await this.searchCustomers(value)
    },
    async companySync(value) {
      if (this.isSearching) {
        clearTimeout(this.isSearching)
        this.isSearching = setTimeout(async () => {
          await this.searchCompanies(value)
          this.isSearching = null
        }, 500)
      }
      await this.searchCompanies(value)
    },
    async customerAccountSync(value) {
      if (this.isSearching) {
        clearTimeout(this.isSearching)
        this.isSearching = setTimeout(async () => {
          await this.searchCustomerAccounts(value)
          this.isSearching = null
        }, 500)
      }
      await this.searchCustomerAccounts(value)
    },
    async eventsSync(value) {
      if (this.isSearching) {
        clearTimeout(this.isSearching)
        this.isSearching = setTimeout(async () => {
          await this.findEvents(value)
          this.isSearching = null
        }, 500)
      }
      await this.findEvents(value)
    },
    mode(value) {
      if (this.internalMode !== 'add') {
        this.resetForm()
      }

      this.internalMode = value
    },
    active() {
      if (this.active && this.internalMode !== 'add') {
        this.internalFormData = JSON.parse(JSON.stringify(this.formData))
        this.setFormData()
      }
    },
    'model.markupType': {
      deep: true,
      handler: function () {
        this.$forceUpdate()
      },
    },
  },
  async mounted() {
    this.internalMode = this.mode
    this.industries = await industries
      .getIndustries({ page: 1, pageSize: -1 })
      .then((data) => data?.data?.resultList)
      .catch((e) => console.error(e))

    this.markupTypes =
      (await this.$store
        .dispatch('types/getMarkupTypes', { page: 1, pageSize: -1 })
        .then((data) => data?.data?.resultList)) ||
      [].catch((e) => console.error(e))

    this.vehicleTypes = await this.$store
      .dispatch('types/getVehicleTypes')
      .then((data) => data.data.resultList)

    this.getMarkets()
  },
  methods: {
    isRequired,
    isNotEmpty,
    getOffsetDate,
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    validateOperatorCost() {
      const { operatorCostHigh, operatorCostLow } = this.model

      if (!operatorCostHigh || !operatorCostLow) {
        return true
      }

      if (Number(operatorCostHigh) < Number(operatorCostLow)) {
        return 'Operator Cost High must be greater than Operator Cost Low'
      }

      return true
    },
    shouldShowFnWrapper(type) {
      if (!this.model.markupType) {
        return true
      }

      return this.model?.markupType?.label === type
    },
    resetForm() {
      for (const type in this.model) {
        this.model[type] = null
      }

      this.marketType = null
      this.dateType = null

      this.internalTypeMode = null
      this.formSubmitted = false
    },
    setFormData() {
      if (this.internalFormData.tripStartDatetime) {
        this.internalFormData.tripStartDatetime = DateTime.fromISO(
          this.internalFormData.tripStartDatetime
        )
      }

      if (this.internalFormData.tripEndDatetime) {
        this.internalFormData.tripEndDatetime = DateTime.fromISO(
          this.internalFormData.tripEndDatetime
        )
      }

      if (this.internalFormData.pricingStartDatetime) {
        this.internalFormData.pricingStartDatetime = DateTime.fromISO(
          this.internalFormData.pricingStartDatetime
        )
      }

      if (this.internalFormData.pricingEndDatetime) {
        this.internalFormData.pricingEndDatetime = DateTime.fromISO(
          this.internalFormData.pricingEndDatetime
        )
      }

      if (this.internalFormData.customer) {
        this.customers = [this.internalFormData.customer]
        this.internalFormData.customer.fullName =
          this.internalFormData.customer.firstName +
          ' ' +
          this.internalFormData.customer.lastName
      }

      if (this.internalFormData.company) {
        this.companies = [this.internalFormData.company]
      }

      if (this.internalFormData.customerAccount) {
        this.customerAccounts = [this.internalFormData.customerAccount]
      }

      if (this.internalFormData.event) {
        this.events = [this.internalFormData.event]
      }

      if (!!this.internalFormData.operatorCostHigh || !!this.internalFormData.operatorCostLow) {
        this.applyBasedOnOperatorCost = true
      }

      for (const prop in this.model) {
        this.model[prop] = this.internalFormData[prop]
      }
      this.model.biddingMarkets = [deepClone(this.internalFormData.biddingMarket)]
      this.model.tripMarkets = [deepClone(this.internalFormData.tripMarket)]
      this.model.vehicleTypes = this.internalFormData.vehicleType
      this.model.rateTypes = this.internalFormData.rateType
      this.model.areaOfInterestType = this.internalFormData.areaOfInterestType
      this.computeDateType()
      this.computeMarketType()
    },
    async searchCustomers(value) {
      if (!value) return

      const [firstName, lastName] = value.split(' ')
      const customerFilters = filter()
      const parentFilter = customerFilters.createParent('and')

      if (firstName) {
        customerFilters.add(parentFilter, {
          column: {
            _t_id: 'nameetc',
            prop: 'firstName',
            filterType: 'contains',
          },
          value: firstName,
        })
      }
      if (lastName) {
        customerFilters.add(parentFilter, {
          column: {
            _t_id: 'nameelasttc',
            prop: 'lastName',
            filterType: 'contains',
          },
          value: lastName,
        })
      }

      const params = {
        filters: customerFilters.asQueryParams(),
        pageSize: 10,
      }
      const matchedCustomers = await customers.getCustomers(params)
      this.customers =
        matchedCustomers?.data?.resultList?.map((cust) => {
          cust.fullName = cust.firstName + ' ' + cust.lastName
          return cust
        }) || []
    },
    async searchCompanies(value) {
      if (!value) return
      const nameFilterObject = {
        column: {
          _t_id: 'company_name_search_id',
          prop: 'name',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const activeFilterObject = {
        column: {
          _t_id: 'company_active_search_id',
          prop: 'active',
          filterType: 'eq',
          filterAsIs: true,
        },
        value: true,
      }

      const companyFilter = filter()
      const parentFilter = companyFilter.createParent('and')
      companyFilter.add(parentFilter, nameFilterObject)
      companyFilter.add(parentFilter, activeFilterObject)

      const params = {
          filters: companyFilter.asQueryParams(),
          pageSize: 5,
        }
      const matchedCompanies = await affiliates.getAffiliates(params)
      this.companies =
      matchedCompanies?.data?.resultList?.map((s) => ({
          ...s,
          display: `${s.name} - ${s.companyType?.key}`,
        })) || []
    },
    async searchCustomerAccounts(value) {
      if (!value) return

      const filterObjects = []
      const affilFilter = filter()

      if (this.search) {
        filterObjects.push({
          column: {
            _t_id: 'text_search_cust',
            prop: 'name',
            filterType: 'contains',
          },
          value,
        })
      }

      filterObjects.forEach((fil) => {
        const parent = affilFilter.createParent('and')
        affilFilter.add(parent, fil)
      })

      const filterParams = affilFilter.asQueryParams()

      this.customerAccounts = await customerAccounts
        .getCustomerAccounts({
          filters: filterParams,
          pageSize: 10,
          page: 1,
        })
        .then(({ data }) => {
          return data.resultList
        })
        .catch((e) => {})
    },
    async findEvents(value) {
      const eventFilters = filter()
      const parentFilter = eventFilters.createParent('and')

      eventFilters.add(parentFilter, {
        column: {
          _t_id: 'title1',
          prop: 'title',
          filterType: 'contains',
        },
        value,
      })

      this.events = await events
        .getEvents({
          filters: eventFilters.asQueryParams(),
          pageSize: 10,
          page: 1,
        })
        .then(({ data }) => {
          return data.resultList
        })
        .catch((e) => {})
    },
    closeHandlerWrapper(refreshStateAfterAction) {
      if (this.internalMode !== 'add') {
        this.internalMode = 'view'
      }

      if (this.internalMode === 'add') {
        this.resetForm()
      }
      this.closeHandler(refreshStateAfterAction)
    },
    async saveHandler(event) {
      event.preventDefault()
      this.formSubmitted = true

      if (!this.$refs.markup.validate()) {
        this.showAlert({
          type: 'error',
          message: 'Missing Fields',
        })

        return
      }

      let markupPercentage = this.model.markupPercentage
      if (typeof this.model.markupPercentage == 'string') {
        markupPercentage.trim().replace('%', '')
      }

      this.cleanDatesBeforeSave()

      const payload = {
        markupTypeId: this.model.markupType
          ? this.model.markupType.markupTypeId
          : null,
        markupPercentage: markupPercentage,
        name: this.model.name,
        biddingMarketId: this.model.biddingMarkets?.[0]?.marketId,
        tripMarketId: this.model.tripMarkets?.[0]?.marketId,
        customerId: this.model.customer ? this.model.customer.customerId : null,
        pricingCompanyId: this.model.company ? this.model.company.companyId : null,
        customerAccountId: this.model.customerAccount
          ? this.model.customerAccount.customerAccountId
          : null,
        industryId: this.model.industryId ? this.model.industryId : null,
        eventId: this.model.event ? this.model.event.eventId : null,
        tripStartDate: this.model.tripStartDatetime,
        tripEndDate: this.model.tripEndDatetime,
        pricingStartDate: this.model.pricingStartDatetime,
        pricingEndDate: this.model.pricingEndDatetime,
        markupDays: (this.model.markupDays || []).map((md) => {
          return { day: md.day }
        }),
        urgencyHoursLow: this.model.urgencyHoursLow,
        urgencyHoursHigh: this.model.urgencyHoursHigh,
        operatorCostLow: this.applyBasedOnOperatorCost ? this.model.operatorCostLow : null,
        operatorCostHigh: this.applyBasedOnOperatorCost ? this.model.operatorCostHigh: null,
        areaOfInterestTypeId: this.model.areaOfInterestType
          ? this.model.areaOfInterestType.id
          : null,
        splitId: this.model.splitId ? this.model.splitId : null,
        externalId: this.model.externalId ? this.model.externalId : null,
      }

      try {
        await markups.update({
          payload,
          id: this.internalFormData.markupId,
        })
        this.showAlert({
          message: 'Successfully edited Markup.',
        })
        EventBus.$emit(
          'reload-pricing-calendar',
          this.model.tripStartDatetime,
          this.model.tripEndDatetime,
          'markup',
          this.internalFormData.markupId
        )
        this.closeHandlerWrapper(true)
      } catch (e) {
        ;(() => {})(e)
        this.showAlert({
          type: 'error',
          message: 'Unable to edit Markup.',
        })
        this.closeHandlerWrapper()
      }
      this.resetForm()
    },
    editHandler(event) {
      event.preventDefault()
      this.internalMode = 'edit'
    },
    computeDateType() {
      if (this.internalFormData.tripStartDatetime || this.internalFormData.tripEndDatetime) {
        this.dateType = 'Trip'
      } else if (this.internalFormData.pricingStartDatetime || this.internalFormData.pricingEndDatetime) {
        this.dateType = 'Pricing'
      }
    },
    computeMarketType() {
      if (this.internalFormData.tripMarket?.marketId) {
        this.marketType = 'Trip'
      } else if (this.internalFormData.biddingMarket?.marketId) {
        this.marketType = 'Bidding'
      }
    },
    async deleteHandler(event) {
      event.preventDefault()
      try {
        await markups.delete(this.internalFormData.markupId)
        this.showAlert({
          message: 'Markup Deleted.',
        })
        this.closeHandlerWrapper(true)
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Unable to delete Markup.',
        })
        this.closeHandlerWrapper()
      }
      this.resetForm()
    },
    async addHandler(event) {
      event.preventDefault()
      this.formSubmitted = true
      if (!this.$refs.markup.validate()) {
        this.showAlert({
          type: 'error',
          message: 'Missing Fields',
        })
        return
      }

      let markupPercentage = this.model.markupPercentage
      if (typeof this.model.markupPercentage == 'string') {
        markupPercentage.trim().replace('%', '')
      }

      this.cleanDatesBeforeSave()

      const payload = {
        markupTypeId: this.model.markupType
          ? this.model.markupType.markupTypeId
          : null,
        markupPercentage,
        name: this.model.name,
        customerId: this.model.customer ? this.model.customer.customerId : null,
        pricingCompanyId: this.model.company ? this.model.company.companyId : null,
        customerAccountId: this.model.customerAccount
          ? this.model.customerAccount.customerAccountId
          : null,
        industryId: this.model.industryId ? this.model.industryId : null,
        eventId: this.model.event ? this.model.event.eventId : null,
        tripStartDate: this.model.tripStartDatetime,
        tripEndDate: this.model.tripEndDatetime,
        pricingStartDate: this.model.pricingStartDatetime,
        pricingEndDate: this.model.pricingEndDatetime,
        markupDays: (this.model.markupDays || []).map((md) => {
          return { day: md.day }
        }),
        urgencyHoursLow: this.model.urgencyHoursLow,
        urgencyHoursHigh: this.model.urgencyHoursHigh,
        operatorCostLow: this.applyBasedOnOperatorCost ? this.model.operatorCostLow : null,
        operatorCostHigh: this.applyBasedOnOperatorCost ? this.model.operatorCostHigh: null,
        areaOfInterestTypeId: this.model.areaOfInterestType
          ? this.model.areaOfInterestType.id
          : null,
        splitId: this.model.splitId ? this.model.splitId : null,
        externalId: this.model.externalId ? this.model.externalId : null,
      }
      let marketPayloads = this.createMultiselectPayloads(payload)
      this.addMarkupsFromPayloads(marketPayloads)
    },
    createMultiselectPayloads(payload) {
      let payloads = []
      let marketPayloads = []
      if (this.model.markupType?.key === 'vehicle_type') {
        this.model.vehicleTypes.map((vehicleType) => {
          this.model.rateTypes.map((rateType) => {
            let vehicleMarketRatePayload = deepClone(payload)
            vehicleMarketRatePayload.vehicleTypeKey = vehicleType.key
            vehicleMarketRatePayload.marketRateTypeKey = rateType.key
            payloads.push(vehicleMarketRatePayload)
          })
        })
      } else {
        payloads.push(payload)
      }

      const marketIdKey = this.marketType === 'Trip' ? 'tripMarketId' : 'biddingMarketId'
      const markets = this.marketType === 'Trip' ? this.model.tripMarkets : this.model.biddingMarkets

      if (markets?.length > 0) {
        payloads.forEach((payloadItem) => {
          markets.forEach((market) => {
            let marketPayload = deepClone(payloadItem)
            marketPayload[marketIdKey] = market.marketId
            marketPayloads.push(marketPayload)
          })
        })
      } else {
        marketPayloads = deepClone(payloads)
      }

      return marketPayloads
    },
    async addMarkupsFromPayloads(payloads) {
      try {
        const result = await markups.createByBatch({
          payload: payloads,
        })
        const listOfResults = result?.data?.markups || []

        const successNumber = listOfResults.length
        const failureNumber = payloads.length - listOfResults.length
        let markupMessage = ''
        if (successNumber > 0) {
          markupMessage = `${successNumber} markup(s) successfully added!`
          EventBus.$emit(
            'reload-pricing-calendar',
            payloads[0].tripStartDate,
            payloads[0].tripEndDate
          )
        }
        if (failureNumber > 0) {
          if (successNumber > 0) {
            markupMessage = `${markupMessage} `
          }
          markupMessage = `${markupMessage}Could not create ${failureNumber} markup(s).`
        }
        this.showAlert({
          type: failureNumber > 0 ? 'error' : null,
          message: markupMessage,
        })
        this.closeHandlerWrapper(true)
        this.resetForm()

      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Could not create markups.',
        })
        return
      }
    },
    async getMarkets() {
      const marketsResponse = await markets.tableView({
        pageSize: -1,
      })
      this.markets = marketsResponse.data.resultList
    },
    toggleAllBiddingMarketsSelected() {
      this.model.biddingMarkets = this.marketsAll ? [] : deepClone(this.markets)
      this.marketsAll = !this.marketsAll
    },
    toggleAllTripMarketsSelected() {
      this.model.tripMarkets = this.marketsAll ? [] : deepClone(this.markets)
      this.marketsAll = !this.marketsAll
    },
    noop() {
      return null
    },
    //We only want to support one date type, so clear
    //fields for other types before save
    cleanDatesBeforeSave() {
      if (this.dateType == 'Pricing') {
        this.model.tripStartDatetime = null
        this.model.tripEndDatetime = null
        this.model.markupDays = null
      } else if (this.dateType == 'Trip') {
        this.model.pricingStartDatetime = null
        this.model.pricingEndDatetime = null
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.markup-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--markup-form {
    margin: 0 !important;
    padding: 15px 40px;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}

.margin-bottom-10 {
  margin-bottom: 10px;
}
</style>
